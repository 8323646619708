/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-ui-dist@1.12.1/jquery-ui.min.js
 * - /npm/jquery-migrate@3.0.1/dist/jquery-migrate.min.js
 * - /npm/bootstrap@3.3.7/dist/js/bootstrap.min.js
 * - /npm/jquery.easing@1.4.1/jquery.easing.min.js
 * - /npm/bootstrap-multiselect@0.9.13-1/dist/js/bootstrap-multiselect.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
